import { applyContentBlockRatioCssPlainCss, customUnblockTriggers, getCookieConsentManager } from "..";
import { getOtherOptionsFromWindow } from "../../utils";
import { render, unmountComponentAtNode } from "react-dom";
import { WebsiteBlocker } from "../../components";
import { applyJQueryEventInitiator, UnblockWatcher } from "@devowl-wp/headless-content-unblocker";
import { createTcfModelFromCookie, getUserDecision, APPLY_INTERACTIVE_EVENT, OPT_IN_ALL_EVENT, BANNER_PRE_DECISION_SHOW_EVENT, transformTcfSrc, decideToUnblockTcfBlockedContent } from "@devowl-wp/cookie-consent-web-client";
import { getSsrPoweredByLink } from "@devowl-wp/react-cookie-banner";

/**
 * Listen to opt-in of all cookies so we can enable the blocked content or
 * create an visual content blocker. It also listens if no / or invalid consent is given.
 */
function listenOptInForContentBlocker() {
  let acceptedCookies = [];
  const {
    setVisualParentIfClassOfParent,
    multilingualSkipHTMLForTag,
    dependantVisibilityContainers,
    blocker: allBlocker,
    tcf,
    tcfMetadata,
    userConsentCookieName,
    pageRequestUuid4
  } = getOtherOptionsFromWindow();
  const watcher = new UnblockWatcher({
    checker: (by, requiredIds, blockerId) => {
      var _allBlocker$filter;
      const blocker = (_allBlocker$filter = allBlocker.filter(_ref => {
        let {
          id
        } = _ref;
        return id === blockerId;
      })) === null || _allBlocker$filter === void 0 ? void 0 : _allBlocker$filter[0];
      let consent = true;
      if (by === "services" || !by) {
        consent = requiredIds.map(requiredId => {
          for (const {
            service: {
              id
            }
          } of acceptedCookies) {
            if (id === +requiredId) {
              return true;
            }
          }
          return false;
        }).indexOf(false) === -1;
      }
      if (process.env.IS_TCF === "1" && by === "tcfVendors") {
        const tcfData = createTcfModelFromCookie(tcf, tcfMetadata, getCookieConsentManager().getOption("tcfCookieName"));
        if (!tcfData) {
          // No consent given yet, fallback to our decision (e.g. Country Bypass and GDPR does not apply for TCF)
          const decision = getUserDecision(userConsentCookieName);
          consent = decision && getOtherOptionsFromWindow().revisionHash === decision.revision;
        } else {
          consent = decideToUnblockTcfBlockedContent(tcfData.model, tcfData.gvl, requiredIds);
        }
      }
      return {
        consent,
        blocker
      };
    },
    overwriteAttributeValue: (value, originalAttribute) => {
      let newValue = value;
      if (process.env.IS_TCF === "1" && originalAttribute === "src") {
        const current = createTcfModelFromCookie(tcf, tcfMetadata, getCookieConsentManager().getOption("tcfCookieName"));
        newValue = transformTcfSrc(newValue, current === null || current === void 0 ? void 0 : current.tcfStringForVendors, current === null || current === void 0 ? void 0 : current.gvl);
      }
      return {
        value: newValue
      };
    },
    overwriteAttributeNameWhenMatches: [{
      // [Plugin Comp] LayTheme
      matches: ".type-video>.video>.ph>%s",
      node: "iframe",
      attribute: "data-src",
      to: "src"
    }],
    transactionClosed: unblockedNodes => {
      customUnblockTriggers(unblockedNodes);
    },
    visual: {
      setVisualParentIfClassOfParent,
      dependantVisibilityContainers,
      unmount: contentBlocker => {
        unmountComponentAtNode(contentBlocker);
      },
      busy: contentBlocker => {
        contentBlocker.style.pointerEvents = "none";
        contentBlocker.style.opacity = "0.4";
      },
      mount: _ref2 => {
        let {
          container,
          blocker,
          onClick,
          thumbnail,
          paintMode,
          blockedNode
        } = _ref2;
        // Do not translate this content blocker with an output buffer plugin like TranslatePress or Weglot
        if (multilingualSkipHTMLForTag) {
          container.setAttribute(multilingualSkipHTMLForTag, "1");
        }
        const blockerDef = {
          ...blocker,
          visualThumbnail: thumbnail || blocker.visualThumbnail
        };
        container.classList.add("wp-exclude-emoji");

        // Yield main thread
        setTimeout(() => render(h(WebsiteBlocker, {
          container: container,
          blockedNode: blockedNode,
          poweredLink: getSsrPoweredByLink(`${pageRequestUuid4}-powered-by`),
          blocker: blockerDef,
          paintMode: paintMode,
          setVisualAsLastClickedVisual: onClick
        }), container), 0);
      }
    },
    customInitiators: (ownerDocument, defaultView) => {
      // [Plugin comp]: TODO: extract to configuration
      applyJQueryEventInitiator(ownerDocument, defaultView, "elementor/frontend/init");
      applyJQueryEventInitiator(ownerDocument, defaultView, "tcb_after_dom_ready"); // Thrive Architect
      applyJQueryEventInitiator(ownerDocument, ownerDocument, "mylisting/single:tab-switched");
      applyJQueryEventInitiator(ownerDocument, ownerDocument, "hivepress:init"); // HivePress
      applyJQueryEventInitiator(ownerDocument, ownerDocument, "wpformsReady"); // WPForms
      applyJQueryEventInitiator(ownerDocument, ownerDocument, "tve-dash.load", {
        // Mark requests as already sent as blocked modules in Thrive never gets loaded again
        onBeforeExecute: () => {
          const {
            TVE_Dash
          } = window;
          TVE_Dash.ajax_sent = true;
        }
      }); // Thrive Leads
    },

    delegateClick: {
      same: [
      // Ultimate Video (WP Bakery Page Builder)
      ".ultv-video__play",
      // Elementor
      ".elementor-custom-embed-image-overlay",
      // Themify
      ".tb_video_overlay",
      // Premium Addons for Elementor
      ".premium-video-box-container",
      // https://themeforest.net/item/norebro-creative-multipurpose-wordpress-theme/20834703
      ".norebro-video-module-sc",
      // WP Video Lightbox
      'a[rel="wp-video-lightbox"]',
      // WP YouTube Lyte
      '[id^="lyte_"]',
      // https://github.com/paulirish/lite-youtube-embed
      "lite-youtube",
      // https://github.com/luwes/lite-vimeo-embed
      "lite-vimeo",
      // https://avada.theme-fusion.com/design-elements/lightbox-element/
      ".awb-lightbox",
      // Impreza (WP Bakery Page Builder)
      ".w-video-h",
      // https://themenectar.com/salient/
      ".nectar_video_lightbox"],
      nextSibling: [
      // JetElements for Element
      ".jet-video__overlay",
      // Elementor
      ".elementor-custom-embed-image-overlay",
      // BeaverBuilder PowerPack Videos
      ".pp-video-image-overlay",
      // Oxygen
      ".ou-video-image-overlay"],
      parentNextSibling: [
      // Divi Page Builder
      {
        selector: ".et_pb_video_overlay",
        hide: true
      }]
    }
  });
  document.addEventListener(APPLY_INTERACTIVE_EVENT, _ref3 => {
    let {
      detail: {
        services
      }
    } = _ref3;
    acceptedCookies = services;
  });
  document.addEventListener(OPT_IN_ALL_EVENT, _ref4 => {
    let {
      detail: {
        services
      }
    } = _ref4;
    acceptedCookies = services;
    watcher.start();
  });
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, () => {
    acceptedCookies = [];
    watcher.start();
  });
  applyContentBlockRatioCssPlainCss();
}
export { listenOptInForContentBlocker };